import React, { FC, useState, useMemo, Fragment } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, LinearProgress, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useExportLeadsList } from './export-leads-list.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { FilterTableContainer } from 'src/app/common/components/filter-table-container/filter-table-container.component';
import { TabBar } from 'src/app/common/components/tab-bar/tab-bar.component';
import { TabValues } from 'src/app/modules/Leads/types/types';
import { MultiExpoButton } from '../../../common/components/talkbot-export-button/talkbot-export-button.component';
import { LeadReminderComponent } from 'src/app/modules/Leads/pages/leads/leads-list/components/lead-list-reminder/components/lead-reminder/lead-reminder.component';
import { AI_TALKBOT_BASE_PATH, AI_TALKBOT_EXPORT_LEADS_PATH, LIST_PATH } from '../../../constants';
import { Info } from '@mui/icons-material';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
    cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
  };

export const ExportLeadsListPage: React.FC<PageProps> = (props: PageProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  // custom-hook
  const {
    autoDropData,
    tabs,
    selectedTab,
    onTabChange,
    setOppCount,
    setFollCount,
    updatePageInfo,
    getTalkbotOpportunities,
    getTalkbbotOpportunitiesColumns,
    opportunitiesFilterConfigs,
    getTalkbotFollowUps,
    getTalkbotFollowUpsColumns,
    followUpFilterConfigs,
  } = useExportLeadsList({ cacheParams: props.cacheParams });

  return (
    <Page>
      {typeof autoDropData?.leadCount === 'number' && autoDropData?.leadCount > 0 ? (
        <div className="tw-mb-[20px]">
          <LeadReminderComponent
            type="warning"
            reminderText={t('sales_lead_autoDrop_remind', {
              autoDropLeadCount: autoDropData?.leadCount,
              autoDropLeadDay: autoDropData?.reminderAutoDropLeadDay ?? 7,
            })}
            confirmText={t('lead.reminder.checkBtn')}
            onConfirm={() => {
              history.push(`${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_LEADS_PATH}${LIST_PATH}/auto-drop`);
            }}
          />
        </div>
      ) : null}

      <HeaderBreadCrumbs />
      <PageTitle
        title={t('title.talkbot.export_leads')}
        rightNode={
          <Button variant="outlined" color="info" onClick={history.goBack}>
            {t('app.button.back')}
          </Button>
        }
      />

      <TabBar tabs={tabs} currentValue={selectedTab} onChange={onTabChange} tabStyle={{ fontSize: 14 }} />

      {selectedTab === TabValues.opportunities && (
        <FilterTableContainer
          rowKey={'_id'}
          cacheParams={{ current: props.cacheParams.current?.[TabValues.opportunities] }}
          getData={getTalkbotOpportunities}
          filterConfigs={opportunitiesFilterConfigs}
          getColumns={getTalkbbotOpportunitiesColumns}
          ActionNode={(props) => <MultiExpoButton {...props} />}
          onLoad={(_, pageInfo) => updatePageInfo(setOppCount, pageInfo)}
        />
      )}

      {selectedTab === TabValues.followUp && (
        <FilterTableContainer
          rowKey={'_id'}
          cacheParams={{ current: props.cacheParams.current?.[TabValues.followUp] }}
          getData={getTalkbotFollowUps}
          filterConfigs={followUpFilterConfigs}
          getColumns={getTalkbotFollowUpsColumns}
          ActionNode={(props) => <MultiExpoButton {...props} />}
          onLoad={(_, pageInfo) => updatePageInfo(setFollCount, pageInfo)}
        />
      )}
    </Page>
  );
};

export const renderOperationHeader = (t: (val: string) => string) => {
  return (
    <Fragment>
      {t('section.common.operation')}
      <Tooltip arrow title={t('talkbot.table.operation.tooltip')}>
        <Info className="tw-ml-[4px]" sx={{ color: '#CCCCCC' }} />
      </Tooltip>
    </Fragment>
  );
};
