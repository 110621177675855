/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useContext, useEffect } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { AuthPage, Logout } from './modules/Auth';
import { RootState } from '../redux/store';
import { AlertItem } from '../redux/common/commonSlice';
import TokenHandler from './common/components/TokenHandler';
import { setTokenInfo } from './modules/Auth/network/authSlice';
import PruRoute from './common/components/PruRoute';
import { TokenInfo } from './modules/Auth/types/auth-types';
import { PRUFORCE_PATH, APPLICATION_BASE_PATH } from './modules/Application/constants';
import Callback from './modules/Auth/pages/Callback';
import ApplicationRoutes from './modules/Application/pages/ApplicationRoutes';
import ParamsProvider from './common/components/ParamsProvider';
import SingleSignOn from './common/components/SingleSignOn';
import ModuleProvider from './common/module/ModuleProvider';
import ErrorsPage from './modules/Error/ErrorsPage';
import { ToastContext } from './common/components/pru-toast/toast-context';
import { GlobalHelper } from './common/helpers/GlobalHelpers';
import { ModalServiceContext } from './common/components/pru-modal/modal-context';
import { ModalService } from './common/components/pru-modal/modal-service';
import GAPanel from './common/ga/components/GAPanel';
import { decodeJWT } from './modules/Auth/network/authCrud';
import { useJWT } from './common/utils/hook-utils';
import { PruAlert } from './layout';
import Layout from './common/components/layout/layout';
import { LeadStatusStepperComponent } from './modules/Leads/components/lead-status-stepper/lead-status-stepper.component';
import { LeadStatusHistoryComponent } from './modules/Leads/components/lead-status-history/lead-status-history.component';
import { LeadCategoryEnum } from './modules/Prospect/types/lead-category-types';
import { InsightSectionComponent } from './modules/Insight/components/insight-section/insight-section.component';

type RoutesInternal = {
  user?: TokenInfo;
  alertState: AlertItem[];
  isExpired: boolean;
};

export const Routes: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const toastRef = useContext(ToastContext);
  GlobalHelper.setGlobalToastRef(toastRef);

  const modalRef = useContext(ModalServiceContext);
  GlobalHelper.setGlobalModalRef(modalRef);

  GlobalHelper.setGlobalToastRef(toastRef);
  const user = useJWT();
  const { alertState, isExpired } = useSelector<RootState, RoutesInternal>((state) => {
    const token = window.localStorage.getItem('jwt') || '';

    if (!token) {
      const redirectLink = window.localStorage.getItem('redirect');
      if (!redirectLink && location.pathname.startsWith('/agencyCampaign/campaign/detail')) {
        window.localStorage.setItem('redirect', location.pathname);
      }
    }

    return {
      alertState: state.common.alertState,
      isExpired: !!state.auth.isExpired,
    };
  }, shallowEqual);

  useEffect(() => {
    const token = window.localStorage.getItem('jwt');

    if (token) {
      decodeJWT(token).then((jwt) => dispatch(setTokenInfo(jwt)));
    }
    ModalService.init();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <GAPanel debug />
      <PruAlert alertState={alertState} />
      <Switch>
        <PruRoute
          path="/callback"
          render={(props) => (
            <ParamsProvider {...props} acceptKeys={['authorizationCode']}>
              <Callback />
            </ParamsProvider>
          )}
        />
        <PruRoute
          path="/:redirect/callback"
          render={(props) => (
            <ParamsProvider {...props} acceptKeys={['authorizationCode']}>
              <Callback />
            </ParamsProvider>
          )}
        />
        <PruRoute
          path={APPLICATION_BASE_PATH}
          render={(props) => (
            <SingleSignOn>
              <ApplicationRoutes {...props} />
            </SingleSignOn>
          )}
        />
        <PruRoute path="/error" component={ErrorsPage} />
        <PruRoute path="/logout" component={Logout} />
        <Redirect from={PRUFORCE_PATH} to={APPLICATION_BASE_PATH} />
        {user && <Redirect from="/auth/login" to="/" />}
        <SingleSignOn>
          {!user ? (
            <AuthPage />
          ) : (
            <ModuleProvider>
              <TokenHandler />
              {!isExpired && <Layout />}
            </ModuleProvider>
          )}
        </SingleSignOn>
      </Switch>
    </>
  );
  // return (
  //   <div style={{ margin: 20, background: '#fff' }}>
  //     <InsightSectionComponent title="haha-cc" />
  //     <InsightSectionComponent title="haha-kk" />
  //   </div>
  // );
  // return (
  //   <div style={{ margin: 20, background: '#fff' }}>
  //     <LeadStatusHistoryComponent
  //       followUp={arrayFollowactive}
  //       leadCategory={LeadCategoryEnum.SALES_LEAD}
  //       leadCreatedAt="2024-04-18T14:14:25.991Z"
  //     />
  //     <LeadStatusHistoryComponent
  //       followUp={arrayFollowDead}
  //       leadCategory={LeadCategoryEnum.SALES_LEAD}
  //       leadCreatedAt="2024-04-18T14:14:25.991Z"
  //     />
  //     <div style={{ marginBottom: 10 }} />

  //     <LeadStatusStepperComponent followUp={arrayFollowactive} />
  //     <LeadStatusStepperComponent followUp={arrayFollowDead} />
  //     <LeadStatusStepperComponent followUp={arrayFollowCompleted} />
  //   </div>
  // );
};
// active
var arrayFollowactive = [
  {
    createdAt: '2024-04-18T14:14:25.991Z',
    status: 'contactSuccess',
    submissionDate: '2024-04-18T14:14:25.768Z',
    expiredAt: '2024-04-19T14:15:26.072Z',
  },
  {
    createdAt: '2024-04-18T14:14:25.991Z',
    status: 'met',
    submissionDate: '2024-04-18T14:14:25.768Z',
    expiredAt: '2024-04-19T14:15:26.072Z',
  },
  {
    createdAt: '2024-04-18T14:14:25.991Z',
    status: 'met',
    submissionDate: '2024-04-18T14:14:25.768Z',
    expiredAt: '2024-04-19T14:15:26.072Z',
  },
];
// dead
var arrayFollowDead = [
  {
    createdAt: '2024-04-18T02:34:32.630Z',
    status: 'contactSuccess',
    submissionDate: '2024-04-18T02:34:32.000Z',
    expiredAt: '2024-04-19T02:35:32.710Z',
  },
  {
    createdAt: '2024-04-18T14:14:25.991Z',
    status: 'met',
    submissionDate: '2024-04-18T14:14:25.768Z',
    expiredAt: '2024-04-19T14:15:26.072Z',
  },
  {
    createdAt: '2024-04-19T03:00:04.928Z',
    status: 'dead',
    submissionDate: '2024-04-19T03:00:04.928Z',
    reason: 'by_lead_update_time&1',
    reasons: ['by_lead_update_time&1'],
  },
];

// completed
var arrayFollowCompleted = [
  {
    submissionDate: '2024-04-18T14:13:10.180Z',
    createdAt: '2024-04-18T14:13:10.180Z',
    status: 'contactSuccess',
  },
  {
    submissionDate: '2024-04-18T14:13:14.292Z',
    status: 'met',
    createdAt: '2024-04-18T14:13:14.588Z',
  },
  {
    submissionDate: '2024-04-18T14:13:14.292Z',
    status: 'quote',
    createdAt: '2024-04-18T14:13:14.588Z',
  },
  {
    submissionDate: '2024-04-18T14:13:14.292Z',
    status: 'proposal',
    createdAt: '2024-04-18T14:13:14.588Z',
  },
  {
    createdAt: '2024-04-18T14:13:14.533Z',
    status: 'policy',
    submissionDate: '2024-04-18T14:13:14.292Z',
    policyNumber: 'auto-policy',
    issuedPolicy: [
      {
        issuedDate: '2024-04-18T14:13:14.292Z',
        policyNumber: 'auto-policy',
      },
    ],
  },
];

export function getI18nData(i18nData: any, lang: string, arrEnglishKey: string[] = ['en', 'enUs', 'default']) {
  if (!i18nData) {
    return '';
  }
  if (typeof i18nData === 'string') {
    return i18nData;
  }
  const data = i18nData[lang];
  if (data) {
    return data;
  }
  // data in English
  let enData;
  for (let i = 0; i < arrEnglishKey.length; i++) {
    const defaultLang = arrEnglishKey[i];
    enData = i18nData[defaultLang];
    if (enData) {
      break;
    }
  }
  if (arrEnglishKey.includes(lang)) {
    return enData;
  }
  // data in non-English
  const arrKey = Object.keys(i18nData); // ['zh-Hant', 'zh-cn', 'default', 'en']
  const nonEnglishKey = arrKey.find((key) => !arrEnglishKey.includes(key));
  return nonEnglishKey ? i18nData[nonEnglishKey] : enData;
}
