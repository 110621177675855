import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getColumns } from './util/lead-column.util';
import { CampaignDetail, CampaignLeadItem, FollowUpStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { i18nKeyMapping } from 'src/app/modules/AgencyCampaign/pages/Campaign/detail/campaign-lead-filter-table/util/campaign-lead-filter-table.util';
import { getLeadList } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { useEventEmitter } from 'ahooks';
import {
  marketingCampaignDetailsGaDataClick,
  marketingCampaignDetailsGaDataClick1,
} from 'src/app/common/ga/types/ga-click-lead';
import { Lead } from 'src/app/modules/Leads/types/types';
import { getEligibleLeads } from 'src/app/modules/ai-talkbot/common/utils/talkbot.utils';
import { useProjectSelector } from 'src/redux/store';

interface HookProps {
  data: CampaignDetail;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  campaignId: string;
  // other params
}

export const useCampaignLeadList = ({ data, cacheParams, campaignId }: HookProps) => {
  // i18n
  const user: any = useSelector<any>((state) => state.auth.user);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [keyword, setKeyword] = useState('');
  const [disabledOp, setDisabledOp] = useState(false);
  const [exportData, setExportData] = useState<Lead[]>();
  const activeFlowIds = useProjectSelector((state) => state.talkbot.activeFlowIds);

  const dispatch = useDispatch();
  const columns = useMemo(
    () =>
      getColumns({
        t: Translation,
        disabledOp,
        operations: { onAutoCall: (item) => setExportData(getEligibleLeads([{ ...item }], activeFlowIds)) },
      }),
    [Translation, disabledOp, activeFlowIds],
  );
  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      const { keyword } = filterState;
      cacheParams && (cacheParams.current = filterState);

      setKeyword(keyword);
    },
    [cacheParams],
  );

  const mapLeadStatus = (lead: any) => {
    if (lead.followUp.length < 1) {
      return FollowUpStatusEnum.pendingToContact;
    } else {
      return lead.followUp[lead.followUp.length - 1].status;
    }
  };

  const convertLeadData = (rawData: any, campaign: any): CampaignLeadItem[] => {
    if (!rawData) return [];
    const leads: CampaignLeadItem[] = [];
    rawData.forEach((lead: any) => {
      const isNew = lead.agentStatus === FollowUpStatusEnum.pending;
      leads.push({
        ...lead,
        _id: lead._id,
        name: lead.prospectDetail.alias,
        labels: lead.labels,
        campaignName: lead.campaign.name,
        sla: lead?.expiredAt ?? '',
        leadSource: campaign.sourceName,
        status: isNew ? FollowUpStatusEnum.pending : mapLeadStatus(lead),
        followUpStatus: lead?.followUpStatus,
        followUp: lead?.followUp,
        reason: lead?.reason,
        reasons: lead?.reasons,
        issuedPolicy: lead?.issuedPolicy,
        updatedAt: lead.updatedAt,
        lastViewedAt: lead.lastViewedAt ?? '',
        leadWithPartialInfoDisplay: data.isLeadWithPartialInfo,
        campaign: {
          ...lead?.campaign,
          leadWithPartialInfoWarning: data.leadWithPartialInfoWarning,
        },
      });
    });
    return leads;
  };

  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
        agentCodes?: string[] | undefined;
        leadState?: string | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      const { page, pageSize, agentCodes, leadState } = params;
      let sortBy = 'leadStatus';
      let sortOrder;
      const searchAgentCode = agentCodes ? agentCodes : [user.agentCode];
      let leadQuery: any = i18nKeyMapping[leadState as keyof typeof i18nKeyMapping]?.query;
      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];
        if (val === 'desc') {
          sortOrder = -1;
        } else {
          sortOrder = 1;
        }

        if (key === 'displayName') {
          if (sortOrder) {
            sortBy = '-nameWeight -firstName';
          } else {
            sortBy = 'nameWeight firstName';
          }
        } else {
          sortBy = `${key}`;
        }
        leadQuery = { ...leadQuery, sortBy: sortBy };
      }

      const res = await getLeadList(
        {
          campaignId,
          agentCode: searchAgentCode,
          page: page + 1,
          leadQuery,
          pageSize,
          sortBy,
          sortOrder: sortOrder ?? -1,
        },
        dispatch,
      );

      if (res) {
        const { docs, totalDocs, ...rest } = res;
        return {
          success: true,
          data: convertLeadData(docs, data) ?? [],
          total: totalDocs,
          ...rest,
        };
      }

      return { success: false, data: [], total: 0 };
    },
    [data],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: any) => null,
      getCheckboxProps: (row: CampaignLeadItem, rows: CampaignLeadItem[]) => {
        if (disabledOp) return { disabled: true };
        return {
          disabled: false,
        };
      },
    }),
    [disabledOp],
  );
  const event = useEventEmitter();
  const oppProviderValue = useMemo(
    () => ({
      gaData: {
        newApp: marketingCampaignDetailsGaDataClick1,
        update: marketingCampaignDetailsGaDataClick,
      },
      event,
    }),
    [event],
  );

  return {
    getData,
    columns,
    rowSelection,
    onFilterChange,
    oppProviderValue,
    setDisabledOp,
    disabledOp,
    exportData,
    setExportData,
  };
};
