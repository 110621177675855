import React, { memo, useMemo } from 'react';
import { useClientReferredBy } from './client-referred-by.hook';
import {
  FullScreenDialog,
  FullScreenDialogProps,
} from 'src/app/common/components/Dialog/full-screen-dialog/full-screen-dialog.component';
import { Box } from '@mui/material';
import ProFilterTable, { CheckType } from 'src/app/common/components/ProTable';
import { useStyles } from './client-referred-by.style';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { CustomerFilterTableComponent } from '../customer-filter-table/customer-filter-table.component';

interface ComponentProps extends Pick<FullScreenDialogProps, 'open' | 'PaperComponent' | 'onClose' | 'keepMounted'> {
  onConfirm?: (rows: Record<string, any>) => void;
  defaultSelectedRows?: any[];
}

export const ClientReferredBy: React.FC<ComponentProps> = memo(
  ({ open, onConfirm, onClose, defaultSelectedRows, keepMounted = true }: ComponentProps) => {
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const { Translation, selectedRow, rowSelection } = useClientReferredBy({
      defaultSelectedRows,
    });

    return (
      <FullScreenDialog
        keepMounted={keepMounted}
        open={open}
        title={Translation('pruleads.prospect.referredBy')}
        onClose={onClose}
        disableConfirm={!(selectedRow && selectedRow.length > 0)}
        onConfirm={() => {
          onConfirm?.(selectedRow!);
        }}
      >
        <Box className={styles.tableContainer}>
          <CustomerFilterTableComponent rowSelection={rowSelection} defaultSelectedRows={defaultSelectedRows} />
        </Box>
      </FullScreenDialog>
    );
  },
);
